<template>
  <div class="hardCodedDashboard max-w-screen-xl m-auto p-6">
    <div v-if="fetchingData || fetchingLifecycleStages" class="w-100 bg-white border rounded-md overflow-hidden" style="height: 90vh" v-loading="true"></div>

    <template v-else>
      <!-- Header -->
      <el-row :gutter="20" class="reportCardContainer">
        <el-col :span="24" class="mt-0 mt-10 text-2xl">
          <div class="inline-block mt-3 mb-4">Sales Performance</div>
          <!-- Timing -->
          <div class="float-right inline-block text-left">
            <div class="text-xs text-gray-400">Report Duration</div>
            <!-- Query Timing -->
            <el-select v-model="timingFilter" size="small" class="w-60">
              <el-option v-for="item in timingOptions" :key="item.value" :label="item.title" :value="item.value"> </el-option>
            </el-select>
            <el-date-picker class="w-20 mt-2" size="small" :editable="false" start-placeholder="Start Date" end-placeholder="End Date" :clearable="false" v-if="timingFilter == 'custom'" v-model="customTimeRange" type="daterange" format="dd-MMM-yyyy" @change="fetchData()"> </el-date-picker>
          </div>
        </el-col>

        <!-- No Data Div -->
        <el-col :span="24" v-if="!data || !this.lifecycleInsights.stages || !this.lifecycleInsights.ltv">
          <NoDataComponent iconClass="fa fa-bar-chart" message="Dashboard Not configured Yet."></NoDataComponent>
          <!-- <div class="w-full rounded-md bg-white border text-center" style="height: 80vh; padding-top: 40vh">Dashboard Not configured Yet</div> -->
        </el-col>

        <template v-else>
          <!-- Sales Performance -->
          <el-col :span="14">
            <!-- Total Sales -->
            <div v-if="$store.state.token.email == 'ravi@growlytics.in'" class="mb-4 bg-white overflow-hidden border border-gray-100 sm:rounded-lg reportCard">
              <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md headerOfCard">Total Sales</div>
              <div class="barChart py-4 px-2" v-if="lifecycleGraph">
                <el-row>
                  <el-col :span="9" class="pl-4">
                    <div class="text-xs text-gray-500">Total Sales</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.total.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ getNumberWithComma(data.total.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-xs text-gray-500">From New Customers</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.repeatOrersInfo.firstTime.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ data.repeatOrersInfo.firstTime.revenuePerc }}%, {{ getNumberWithComma(data.repeatOrersInfo.firstTime.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="7">
                    <div class="text-xs text-gray-500">From Existing Customers</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.repeatOrersInfo.repeat.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ data.repeatOrersInfo.repeat.revenuePerc }}%, {{ getNumberWithComma(data.repeatOrersInfo.repeat.orders) }} Orders</div>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!-- Growlytics Sales -->
            <div class="bg-white overflow-hidden border border-gray-100 sm:rounded-lg reportCard">
              <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md headerOfCard">Sales From Growlytics</div>
              <div class="barChart py-4 px-2" v-if="lifecycleGraph">
                <el-row>
                  <el-col :span="9" class="pl-4">
                    <div class="text-xs text-gray-500">Revenue From Growlytics</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ data.fromGrowlytics.revenuePerc }}%, {{ getNumberWithComma(data.fromGrowlytics.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-xs text-gray-500">From Automations</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.distribution.automation.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ data.fromGrowlytics.distribution.automation.revenuePerc }}%, {{ getNumberWithComma(data.fromGrowlytics.distribution.automation.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="7">
                    <div class="text-xs text-gray-500">From One-time Campaigns</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.distribution.oneTime.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ data.fromGrowlytics.distribution.oneTime.revenuePerc }}%, {{ getNumberWithComma(data.fromGrowlytics.distribution.oneTime.orders) }} Orders</div>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!-- Channel Wise Sales -->
            <div class="mt-4 bg-white overflow-hidden border border-gray-100 sm:rounded-lg reportCard">
              <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md headerOfCard">Channel Wise Performance</div>
              <div class="barChart py-4 px-2" v-if="lifecycleGraph">
                <el-row>
                  <el-col :span="9" class="pl-4">
                    <div class="text-xs text-gray-500">Email</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.distribution.channelWise.email.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.email.revenuePerc) }}%, {{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.email.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-xs text-gray-500">SMS</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.distribution.channelWise.sms.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.sms.revenuePerc) }}%, {{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.sms.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="7">
                    <div class="text-xs text-gray-500">Whats App</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.distribution.channelWise.whatsApp.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.whatsApp.revenuePerc) }}%, {{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.whatsApp.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="9" class="pl-4 mt-10">
                    <div class="text-xs text-gray-500">Web Push</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.distribution.channelWise.webPush.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.webPush.revenuePerc) }}%, {{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.webPush.orders) }} Orders</div>
                  </el-col>
                  <el-col :span="8" class="mt-10">
                    <div class="text-xs text-gray-500">Mobile Push</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(data.fromGrowlytics.distribution.channelWise.mobilePush.revenue) }}</div>
                    <div class="text-xs text-gray-600">&nbsp;{{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.mobilePush.revenuePerc) }}%, {{ getNumberWithComma(data.fromGrowlytics.distribution.channelWise.mobilePush.orders) }} Orders</div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>

          <!-- Customer Lifecycle Stages -->
          <el-col :span="10">
            <!-- Lifecycle Chart Card -->
            <div class="bg-white overflow-hidden border border-gray-100 sm:rounded-lg reportCard">
              <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md headerOfCard">
                Customer Lifecycle Stages
                <div class="float-right text-xs text-gray-500 mt-2 -mr-1">{{ lifecycleInsights.retentionDays }} Days Retention Period</div>
              </div>
              <div class="barChart pt-4 pb-2 px-2" v-if="lifecycleGraph">
                <highcharts class="eventsChart" ref="lineGraph" :options="lifecycleGraph"></highcharts>
              </div>
            </div>

            <!-- Lifecycle VAlue Card -->
            <div class="mt-4 bg-white overflow-hidden border border-gray-100 sm:rounded-lg reportCard">
              <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md headerOfCard">
                Customer Lifetime Value
                <div class="float-right text-xs text-gray-500 mt-2 -mr-1">Last 1 Year</div>
              </div>
              <div class="barChart py-4 px-2" v-if="lifecycleGraph">
                <el-row>
                  <el-col :span="8" class="pl-4">
                    <div class="text-xs text-gray-500">CLTV</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(lifecycleInsights.ltv.ltv) }}</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-xs text-gray-500">Avg Order Amount</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ $store.state.token.currency + getNumberWithComma(lifecycleInsights.ltv.avgOrderAmount) }}</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-xs text-gray-500">Avg Repeat Order Gap</div>
                    <div class="text-xl mt-1.5 mb-1.5 text-primary-800">{{ lifecycleInsights.ltv.orderGapInDays }} Days</div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </template>
      </el-row>
    </template>
  </div>
</template>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.hardCodedDashboard {
  .menuBetaTag {
    line-height: 16px;
    font-size: 9px;
    vertical-align: middle;
    // background: linear-gradient(90deg, #a7aaa7, #39d806);
    background: #ed2c2c;
    color: white;
    display: inline-block;
    letter-spacing: 1px;

    margin: -2px 10px 0px 10px;
    padding: 2px 10px 2px 10px;
    border-radius: 10px;
  }

  .reportCardContainer {
    .reportCard {
      .cardTitle {
        border-bottom: 1px solid #777;
        margin-bottom: 10px;
        padding-bottom: 5px;
      }
      .label {
        color: #555;
        font-size: 12px;
      }
      .valuePerc {
        font-size: 24px;
        color: $primary;
        font-weight: bold;
        opacity: 0.9;
      }
      .value {
        font-size: 12px;
        color: #555;
        font-weight: bold;
        opacity: 0.8;
        padding-left: 4px;
      }
    }
  }
}
</style>

<script>
import dashboardService from '@/services/dashboard';
import NoDataComponent from '@/pages/others/noData/noData';
export default {
  data() {
    return {
      timingFilter: 'last-30',
      customTimeRange: null,

      fetchingData: true,
      fetchingLifecycleStages: true,
      data: null,

      lifecycleInsights: null,
      lifecycleGraph: null,
      currency: null,

      // Timing properties
      timingOptions: [
        {
          title: 'Last 7 Days',
          value: 'last-7'
        },
        {
          title: 'Last 15 Days',
          value: 'last-15'
        },
        {
          title: 'Last 30 Days',
          value: 'last-30'
        },
        {
          title: 'Last 60 Days (2 Months)',
          value: 'last-60'
        },
        {
          title: 'Last 90 Days (3 Months)',
          value: 'last-90'
        },
        {
          title: 'Last 180 Days (6 Months)',
          value: 'last-180'
        },
        {
          title: 'Last 365 Days (1 Year)',
          value: 'last-365'
        },
        {
          value: 'custom',
          title: 'Custom Range'
        }
      ]
    };
  },
  components: {
    NoDataComponent
  },
  watch: {
    timingFilter: {
      handler: function () {
        console.log('ye..filter changed..');
        if (this.timingFilter != 'custom') {
          this.fetchData();
        }
      }
    }
  },
  methods: {
    async fetchData() {
      this.fetchingData = true;
      let result = await dashboardService.fetchHardcodedDashboardData({
        tab: 'general-tab',
        timingFilter: this.timingFilter,
        customTimeRange: this.customTimeRange
      });
      this.data = result.data;
      this.fetchingData = false;
    },

    async fetchLifecycleData() {
      this.fetchingLifecycleStages = true;
      let result = await dashboardService.fetchLifecycleStagesData({});

      this.lifecycleInsights = result.data;

      // Draw Graph.
      if (this.lifecycleInsights.stages) {
        let xAxisCategories = ['Only Registered', 'New', 'Active', 'About To Churn', 'Churned'];
        let yAxisData = [this.getNumberWithComma(this.lifecycleInsights.stages.registered.total), this.getNumberWithComma(this.lifecycleInsights.stages.new.total), this.getNumberWithComma(this.lifecycleInsights.stages.active.total), this.getNumberWithComma(this.lifecycleInsights.stages.aboutToChurn.total), this.getNumberWithComma(this.lifecycleInsights.stages.churned.total)];
        let yAxisPerc = [this.lifecycleInsights.stages.registered.perc, this.lifecycleInsights.stages.new.perc, this.lifecycleInsights.stages.active.perc, this.lifecycleInsights.stages.aboutToChurn.perc, this.lifecycleInsights.stages.churned.perc];

        // let vueObj = this;
        this.lifecycleGraph = {
          height: 200,
          chart: { height: 215, type: 'column' },
          title: {
            text: null
          },

          xAxis: {
            categories: xAxisCategories
          },

          yAxis: {
            min: 0,
            title: {
              text: null
            },
            stackLabels: {
              enabled: true,
              formatter: function () {
                return this.total + '%';
              },
              style: {
                fontWeight: 'bold',
                color: 'gray',
                verticalAlign: 'bottom',
                crop: false,
                overflow: 'none',
                y: -275
              }
            }
          },

          tooltip: {
            formatter: function () {
              return `<b>${this.point.category}</b><br />
                ${yAxisData[this.point.x]}`;
            }
          },
          plotOptions: {
            series: {
              colorByPoint: true
            },
            column: {
              stacking: 'normal',

              dataLabels: {
                enabled: false
              }
            }
          },
          colors: ['#36454f', '#008080', '#32cd32', '#ffdf00', '#FF3C28'],
          series: [
            {
              name: 'Lifecycle Stage',
              data: yAxisPerc,
              showInLegend: false
            }
          ]
        };
      }

      this.fetchingLifecycleStages = false;
    }
  },
  mounted() {
    this.fetchData();
    this.fetchLifecycleData();
    this.currency = this.$store.state.token.currency;
  }
};
</script>
